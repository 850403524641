/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/features", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/features">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Features</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/licenses", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/licenses">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")}/>
            </span>
              <span className="menu-text">License Keys</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/userLicenses", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/userLicenses">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")}/>
            </span>
              <span className="menu-text">User Licenses</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/referencePartners", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/referencePartners">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Reference Partners</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/subscriptionCertificates", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/subscriptionCertificates">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Shield-check.svg")}/>
            </span>
              <span className="menu-text">Subscription Certificates</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          
          {/* Components */}
          {/* begin::section */}
          {/* <li className="menu-section ">
            <h4 className="menu-text">Components</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li> */}
          {/* end:: section */}

          {/* Material-UI */}
          {/*begin::1 Level*/}
          {/* <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/google-material", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/>
            </span>
              <span className="menu-text">Material UI</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Material UI</span>
                </span>
                </li> */}

                {/* Inputs */}
                {/*begin::2 Level*/}
                {/* <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/google-material/inputs", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/google-material/inputs">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Inputs</span>
                    <i className="menu-arrow"/>
                  </NavLink>
                  <div className="menu-submenu ">
                    <i className="menu-arrow"/>
                    <ul className="menu-subnav"> */}
                      {/*begin::3 Level*/}
                      {/* <li
                          className={`menu-item  ${getMenuItemActive(
                              "/google-material/inputs/autocomplete"
                          )}`}
                          aria-haspopup="true"
                      >
                        <NavLink className="menu-link"
                              to="/google-material/inputs/autocomplete">
                          <i className="menu-bullet menu-bullet-dot">
                            <span/>
                          </i>
                          <span className="menu-text">Autocomplete</span>
                        </NavLink>
                      </li> */}
                      {/*end::3 Level*/}
                    {/* </ul>
                  </div>
                </li> */}
                {/*end::2 Level*/}
            {/* </div>
          </li> */}
          {/*end::1 Level*/}

          {/* Bootstrap */}
          {/*begin::1 Level*/}
          {/* <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/react-bootstrap", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}/>
            </span>
              <span className="menu-text">Bootstrap</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                <ul className="menu-subnav">
                  <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                  >
                  <span className="menu-link">
                    <span className="menu-text">Bootstrap</span>
                  </span>
                  </li> */}

                  {/*begin::2 Level*/}
                  {/* <li
                      className={`menu-item ${getMenuItemActive(
                          "/react-bootstrap/alert"
                      )}`}
                      aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/react-bootstrap/alert">
                      <i className="menu-bullet menu-bullet-dot">
                        <span/>
                      </i>
                      <span className="menu-text">Alerts</span>
                    </NavLink>
                  </li> */}
                  {/*end::2 Level*/}

                {/* </ul>
              </ul>
            </div>
          </li> */}
          {/*end::1 Level*/}
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
