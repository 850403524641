export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  // Response interceptor for handling 401 errors
  axios.interceptors.response.use(
    (response) => {
      // If the response is successful, return it
      return response;
    },
    (error) => {
      // If the response status is 401 (Unauthorized), redirect to '/auth/login'
      if (error.response.status === 401) {
        // Removes auth from localstorage
        localStorage.removeItem("persist:v706-demo1-auth")
        // Redirect to '/auth/login'
        window.location.href = "/auth/login";
      }
      // Return any other errors as is
      return Promise.reject(error);
    }
  );
}
