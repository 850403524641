import axios from "axios";
import { API_URL } from "../../../config/config";

export const LOGIN_URL = API_URL+"api/user/login";
export const REGISTER_URL = API_URL+"api/user/register";
export const REQUEST_PASSWORD_URL = API_URL+"api/user/forgot-password";

export const ME_URL = API_URL+"api/user/me";

export function login(username, password) {
  return axios.post(LOGIN_URL, { username: username, password: password});
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
